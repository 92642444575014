import gql from 'graphql-tag';

export const BUNDLE_LIST_QUERY = gql`
  query serialBundles($page: Int!, $pageSize: Int!) {
    serial {
      bundles(page: $page, pageSize: $pageSize) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            id
            title
            count
            type
            targetId
            status
            description
          }
        }
      }
    }
  }
`;

export const SERIAL_BUNDLE_QUERY = gql`
  query serialBundle($id: ID!) {
    serial {
      bundle(id: $id) {
        id
        title
        description
        count
        type
        targetId
      }
    }
  }
`;

export const POLICY_LIST_QUERY = gql`
  query pointPolicies($page: Int!, $pageSize: Int!) {
    serial {
      pointPolicies(page: $page, pageSize: $pageSize) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            id
            name
            description
            point
            status
            pointExpirationMethod
            expiresAfter
            expiresAt
            bonusPoint
            startAt
            endAt
          }
        }
      }
    }
  }
`;

export const POLICY_QUERY = gql`
  query pointPolicy($id: ID!) {
    serial {
      pointPolicy(id: $id) {
        id
        name
        description
        point
        type
        status
        pointExpirationMethod
        expiresAfter
        expiresAt
        bonusPoint
        startAt
        endAt
      }
    }
  }
`;

export const EXPORTS_QUERY = gql`
  query exports($page: Int!, $pageSize: Int!, $bundleId: ID!) {
    serial {
      exportsByBundle(page: $page, pageSize: $pageSize, bundleId: $bundleId) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            id
            requester {
              id
              nickname
            }
            status
            completedAt
          }
        }
      }
    }
  }
`;

export const DOWNLOAD_URL_QUERY = gql`
  query getDownloadUrl($id: ID!) {
    serial {
      serialFileDownloadUrl(id: $id)
    }
  }
`;

export const BUNDLE_SERIALS_QUERY = gql`
  query BundleSerials(
    $page: Int!
    $pageSize: Int!
    $bundleId: Int!
    $serialNos: [String]
  ) {
    serial {
      serialsBySerialNos(
        page: $page
        pageSize: $pageSize
        bundleId: $bundleId
        serialNos: $serialNos
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            serialNo
            bundle {
              id
              title
              description
              count
              targetId
              status
            }
            status
            used
            usedBy {
              id
              nickname
            }
            usedAt
          }
        }
      }
    }
  }
`;
