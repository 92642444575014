





















































import Vue from 'vue';
import InlineInput from '@/components/Forms/InlineInput.vue';
import InlineRadioForm from '@/components/Forms/InlineRadioInput.vue';

export default Vue.extend({
  name: 'SerialBundleForm',
  components: {
    InlineInput,
    InlineRadioForm,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        title: '',
        description: '',
        type: 'COUPON',
        targetId: '',
        count: 0,
      },
      bundleTypeOptions: [
        {
          text: '쿠폰',
          value: 'COUPON',
        },
        {
          text: '에너지',
          value: 'POINT',
        },
      ],
    };
  },
  created() {
    console.log('bundle value: ', this.value);
    this.$data.form = this.value;
  },
  methods: {
    onInput() {
      this.$emit('input', this.$data.form);
    },
  },
});
