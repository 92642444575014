


































































import Vue from 'vue';
import Spinner from '@/components/Spinner.vue';
import BundleForm from '../components/BundleForm.vue';
import { serialService } from '../service/serialService';
import { ExportConnection, SerialBundle } from '../model/serial';
import { EXPORTS_QUERY, SERIAL_BUNDLE_QUERY } from '../queries/query';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import BundleExportList from '../components/BundleExportList.vue';
import { convertToBundleParams } from '../util/serialConverter';

export default Vue.extend({
  name: 'BundleDetail',
  components: {
    Spinner,
    BundleForm,
    BundleExportList,
  },
  data() {
    return {
      bundleId: 0,
      loading: 0,
      bundle: {
        title: '',
        description: '',
        type: 'COUPON',
        targetId: '',
        count: 0,
      },
      showErrorMessageTime: 0,
      errorMessage: '',
      exportList: null,
      page: this.$route.query.page || 1,
      size: this.$route.query.size || 5,
      totalRows: 0,
    };
  },
  computed: {
    bundleDetailTitle() {
      if (this.bundleId > 0) {
        return this.$data.bundle.title;
      } else {
        return '신규 시리얼 번들 등록';
      }
    },
  },
  created() {
    if (this.$route.query.id) {
      console.log(this.$route.query.type);
      this.$data.bundle.targetId = this.$route.query.id;
      this.$data.bundle.type = this.$route.query.type;
    }
  },
  methods: {
    showErrorMessage(message: string) {
      this.$data.showErrorMessageTime = 5;
      this.$data.errorMessage = message.replace('GraphQL error:', '');
    },
    countDownChanged(showErrorMessageTime: number) {
      this.$data.showErrorMessageTime = showErrorMessageTime;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageClick(bvEvent: any, page: number) {
      console.log('page clicked!' + page);
      if (
        this.$route.path !==
        `/bundle/list/${this.$data.bundleId}?page=${page}&size=${this.$data.size}`
      ) {
        this.$router.push(
          `/serial/bundle/list/${this.$data.bundleId}?page=${page}&size=${this.$data.size}`
        );
      }
    },
    async createBundle() {
      try {
        console.log(this.$data.bundle);
        await serialService.createBundle(
          convertToBundleParams(this.$data.bundle)
        );
        this.$router.push('/serial/bundle/list');
      } catch (error) {
        console.error(error);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any).showErrorMessage(error.message);
      }
    },
    async exportSerials() {
      try {
        await serialService.exportSerials(this.$route.params.id);
        this.$apollo.queries.exports.refresh();
        this.$apollo.queries.exports.startPolling(300);
      } catch (error) {
        console.error(error);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any).showErrorMessage(error.message);
      }
    },
    async deactivate() {
      if (confirm('정말로 비활성화 하시겠습니까?')) {
        try {
          await serialService.deactivateBundle(this.$route.params.id);
          this.$router.push('/serial/bundle/list');
          console.log('deactivate');
        } catch (error) {
          console.error(error);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this as any).showErrorMessage(error.message);
        }
      }
    },
  },
  apollo: {
    serial: {
      query: SERIAL_BUNDLE_QUERY,
      variables() {
        return {
          id: this.$route.params.id || 0,
        };
      },
      error(error: ApolloError) {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      result(result: ApolloQueryResult<{ serial: { bundle: SerialBundle } }>) {
        const bundle = result.data?.serial.bundle;
        console.log(bundle);
        if (bundle) {
          this.$data.bundleId = bundle.id;
          this.$data.bundle.title = bundle.title;
          this.$data.bundle.description = bundle.description;
          this.$data.bundle.type = bundle.type;
          this.$data.bundle.targetId = bundle.targetId;
          this.$data.bundle.count = bundle.count;
        }
      },
      skip() {
        return !this.$route.params.id;
      },
    },
    exports: {
      query: EXPORTS_QUERY,
      variables() {
        return {
          page: Number(this.$data.page),
          pageSize: Number(this.$data.size),
          bundleId: this.$data.bundleId,
        };
      },
      error(error: ApolloError) {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      result(
        result: ApolloQueryResult<{
          serial: { exportsByBundle: ExportConnection };
        }>
      ) {
        const exports = result.data?.serial.exportsByBundle;
        console.log('exports');
        console.log(exports);
        this.$data.exportList = exports.edges;
        this.$data.totalRows = result.data.serial.exportsByBundle.totalCount;
        if (this.$data.exportList[0].node.status == 'CREATED') {
          this.$apollo.queries.exports.stopPolling();
        }
      },
      skip() {
        return !this.$route.params.id;
      },
      update: data => data.exports,
    },
  },
});
